const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

function getTotal(product, addons, site, donation, paymentOpt) {
  let total = 0;
  if (product) total += product.price;
  total += addons.reduce((sum, addon) => sum + addon.price, 0);
  if (site) total += (site.fee || 0);
  total += (+donation || 0)
  if (paymentOpt) total += (paymentOpt.fee || 0);

  return total;
}

function getDue(product, addons, site, donation, paymentOpt) {
  let due = getTotal(product, addons, site, donation, paymentOpt);
  if (!paymentOpt) return due;
  
  if (paymentOpt.onlinePriceOverride) {
    due = paymentOpt.onlinePriceOverride;
  } 

  if (paymentOpt.paymentCount > 1) {
    due /= paymentOpt.paymentCount;
  }

  return due;
}

const dayNames = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Day TBD' ];

function mapYamlEdge(edge) {
  const node = {
    ...edge.node,
    id: edge.node.fields.slug
  }
  delete node.fields;
  return node;
}

function isPaymentOptInRange(paymentOpt) {
  const now = new Date();
  const year = now.getFullYear();

  let start, end;
  if (paymentOpt.startDate) {
    start = new Date(...dateComponents(paymentOpt.startDate));
    start.setFullYear(year);
  }
  if (paymentOpt.endDate) {
    end = new Date(...dateComponents(paymentOpt.endDate));
    end.setFullYear(year);
    end.setDate(end.getDate() + 1);
  }

  if (!start && !end) {
    return true;
  } else if (start && end) {
    if (start.getTime() < end.getTime()) {
      return now.getTime() >= start.getTime() && now.getTime() < end.getTime();
    } else {
      return now.getTime() >= start.getTime() || now.getTime() < end.getTime();
    }
  } else if (start) {
    return now.getTime() >= start.getTime();
  } else if (end) {
    return now.getTime() < end.getTime();
  }
}

function dateComponents(dateStr) {
  return [
    parseInt(dateStr.substring(0, 4), 10),
    parseInt(dateStr.substring(5, 7), 10) - 1,
    parseInt(dateStr.substring(8, 10), 10),
  ]
}

// Note: remove paragraphs wrapping a single variable only
function replaceVars(original, substitutions) {
  return Object.entries(substitutions).reduce((html, [sub, val]) => {
    return html.replace(new RegExp(`${sub}|<p>${sub}</p>`, 'g'), () => val);
  }, original);
}

function validateAddonOptions(addons, addonOptions) {
  return addons.every(addon => {
    return (addon.options || []).every(({name, choices}) => {
      return choices.includes(addonOptions[addon.id][name])
    })
  })
}

module.exports = {
  priceFormatter,
  getTotal,
  getDue,
  dayNames,
  mapYamlEdge,
  isPaymentOptInRange,
  replaceVars,
  validateAddonOptions
}