import {lineHeight} from './constants'
import { css, keyframes } from '@emotion/core';

export function rhythm(factor) {
  return Math.round(factor * lineHeight * 1000) / 1000 + 'rem';
}

export function setFontSize(perc, lines=0) {
  // auto-calculate lines if not provided
  // nearest half rhythm unit that results in a line-height greater than 1
  if (!lines) {
    lines = Math.ceil(2 / (lineHeight / perc * 100)) / 2;
  }

  return {
    fontSize: perc / 100 + 'em',
    lineHeight: lineHeight / perc * 100 * lines
  }
}

export const center = css`text-align: center;`;

export const blockImg = css`
  display: block;
  margin: ${rhythm(1)} 0;
`;

export const padded = css`
  padding-left: ${rhythm(1)};
  padding-right: ${rhythm(1)};
`

export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

export const clearFix = css`
  &:after {
    content: "";
    display: table;
    clear: both;
  }
`

export const scrollShadowVertical = css`
  background: 
    /* covers */
    linear-gradient(white 20px, transparent 40px),
    linear-gradient(to top, white 20px, transparent 40px),

    /* shadows */
    linear-gradient(rgba(0,0,0,.2), transparent 10px),
    linear-gradient(to top, rgba(0,0,0,.2), transparent 10px);
  
  background-color: white;
  background-attachment: local, local, scroll, scroll;
`
