import React, { useState, useRef } from 'react'
import {graphql} from 'gatsby';
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/Seo'

import ProductStep from '../components/shop/steps/ProductStep';
import PickupStep from '../components/shop/steps/PickupStep';
import DonationStep from '../components/shop/steps/DonationStep';
import PaymentOptStep from '../components/shop/steps/PaymentOptStep';
import CheckoutStep from '../components/shop/steps/CheckoutStep';

import {Steps} from '../components/shop/Steps';
import util from 'src/util';
import settings from '../../content/settings/general.json';

const ShopPage = ({data}) => {
  const page = data.file.childMarkdownRemark.frontmatter;
  const {html} = data.file.childMarkdownRemark;

  const [productId, setProductId] = useState('');
  const [addonIds, setAddonIds] = useState({});
  const [siteId, setSiteId] = useState('');
  const [day, setDay] = useState('');
  const [donation, setDonation] = useState(0);
  const [paymentOptId, setPaymentOptId] = useState('');
  const [checkoutFields, setCheckoutFields] = useState({ first: '', last: '', email: '', phone: '', address: '', city: '', state: '', zip: '', comments: '', agree: false });
  const [paymentStatus, setPaymentStatus] = useState('unpaid'); // unpaid, processing, success

  const allProducts = data.allProductsYaml.edges.map(util.mapYamlEdge);
  const allAddons = data.allProductAddonsYaml.edges.map(util.mapYamlEdge);
  const allSites = data.allProductSitesYaml.edges.map(util.mapYamlEdge);
  const allPaymentOpts = data.allPaymentOptionsYaml.edges.map(util.mapYamlEdge).filter(util.isPaymentOptInRange);

  const product = allProducts.find(p => p.id == productId);
  const addons = allAddons.filter(a => !!addonIds[a.id]);
  const site = allSites.find(site => site.id == siteId);
  const paymentOpt = allPaymentOpts.find(o => o.id == paymentOptId);

  const getTotal = util.getTotal.bind({}, product, addons, site, donation, paymentOpt);
  const getDueToday = util.getDue.bind({}, product, addons, site, donation, paymentOpt);

  function canVisitStep(step) {
    if (paymentStatus !== 'unpaid') return false;

    switch (step + 1) {
      case 5: if (!paymentOptId) return false;
      case 4:
      case 3: if (!day || !siteId) return false;
      case 2: if (!productId || !util.validateAddonOptions(addons, addonIds)) return false;
      case 1:
      default: return true;
    }
  }  
  
  return (
    <Layout>
      <SEO title={page.title} description={page.description} />
      <h1>{page.title}</h1>
      {html && <Container width="600px" dangerouslySetInnerHTML={{__html: html}} />}

      {!settings.disableShop && <Steps totalPrice={getTotal()} dueToday={getDueToday()} canVisitStep={canVisitStep}>
        <ProductStep
          {...{allProducts, productId, setProductId, allAddons, addonIds, setAddonIds}}
          {...page.productStep}
        />

        <PickupStep
          allSites={allSites}
          siteId={siteId} setSiteId={setSiteId}
          day={day} setDay={setDay}
          {...page.pickupStep}
        />

        <DonationStep 
          donation={donation} setDonation={setDonation} 
          {...page.donationStep} 
        />

        <PaymentOptStep
          allPaymentOpts={allPaymentOpts}
          paymentOptId={paymentOptId} setPaymentOptId={setPaymentOptId}
          {...page.paymentOptStep}
        />

        <CheckoutStep
          {...{product, addons, site, donation, paymentOpt}}
          {...{productId, addonIds, siteId, day, paymentOptId}}
          {...{checkoutFields, setCheckoutFields, paymentStatus, setPaymentStatus}}
          {...page.checkoutStep}
        />
      </Steps>}
    </Layout>
  )
}

export default ShopPage;

export const pageQuery = graphql`
  query {
    file(relativePath: {eq: "pages/shop.md"}) {
      childMarkdownRemark {
        frontmatter {
          title
          description
          productStep { title description addonsText }
          pickupStep { title description }
          donationStep { title description }
          paymentOptStep { title description }
          checkoutStep { title description summaryText agreementFile { publicURL } }
        }
        html
      }
    }

    allProductsYaml(sort: {fields: order}) {
      edges {
        node {
          fields { slug }
          name
          description
          price
          image {
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allProductSitesYaml(filter: {disable: {ne: true}}, sort: {fields: order}) {
      edges {
        node {
          fields { slug }
          name
          address
          fee
          feeDescription
          days
          flag
        }
      }
    }
    allProductAddonsYaml(sort: {fields: order}) {
      edges {
        node {
          fields { slug }
          name
          description
          price
          image {
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          options {
            name
            choices
          }    
        }
      }
    }
    allPaymentOptionsYaml(sort: {fields: order}) {
      edges {
        node {
          fields { slug }
          name
          description
          fee
          frequency
          paymentCount
          onlinePriceOverride
          chargeDescription
          startDate
          endDate
          invisible
        }
      }
    }
  }
`