import React, {useState} from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {fontFamily, lineHeight} from 'src/styles/constants';
import Input from '../Input';
import { rhythm, setFontSize } from '../../styles/util';
import { css } from '@emotion/core';
import Columns from '../Columns';
import Button from '../Button';
import Center from '../Center';
import axios from 'axios';
import Message from '../Message';


const cardStyle = {
  base: {
    fontFamily,
    fontSize: '17px',
    lineHeight
  }
}

const inputStyle = css`
  display: block; 
  width: 100%; 
  box-sizing: border-box; 
  margin-bottom: ${rhythm(1/2)}; 
`

const Textarea = Input.withComponent('textarea')
const textareaStyle = css`padding: ${rhythm(1/4)}; line-height: 1.3;`
const commentsCounterStyles = css`
  display: none;
  position: absolute;
  right: 0;
  opacity: .6;
  ${setFontSize(75, .75)};
  margin-top: ${rhythm(-1/2)};
  textarea:focus + & { display: block; }
`

function CheckoutForm({orderDetails, fields, setFields, status, setStatus, agreementUrl}) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  const fieldSetter = (field, prop='value') => e => setFields({...fields, [field]: e.target[prop]});
  const showError = error => { setError(error); setStatus('unpaid'); }
  const showSuccess = () => { setError(null); setStatus('success'); }
  const updateComments = e => setFields({...fields, comments: e.target.value.substring(0, 500)});

  async function handleSubmit(e) {
    e.preventDefault();    
    if (status !== 'unpaid') return;
    setStatus('processing');

    const {first, last, email, phone, address: line1, city, state, zip, comments} = fields;
    const name = first + ' ' + last;
    const address = {line1, city, state, postal_code: zip};
    let error, paymentMethod, paymentIntent;

    ({error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: { name, email, phone, address }
    }));

    if (error) return showError(error.message);
    
    ({error, paymentIntent} = await axios.post('/.netlify/functions/process-payment', {
      name,
      email,
      phone,
      address,
      paymentMethod: paymentMethod.id,
      orderDetails: {...orderDetails, comments},
    }, {validateStatus: s => s < 500})
      .then(res => res.data)
      .catch(e => ({error: {message: 'A network error has occurred.'}})));

    // guard against unexpected outcomes
    if (!paymentIntent) error = {message: 'Sorry, an unexpected error has occurred'};

    if (error) return showError(error.message);
    
    if (paymentIntent.status === 'requires_action' || paymentIntent.status === 'requires_confirmation') {
      const {error} = await stripe.confirmCardPayment(paymentIntent.client_secret);

      if (error) {
        showError(error.message)
      } else {
        showSuccess();
      }
    } else showSuccess();
  }

  if (status === 'success') return null;

  return (
    <form onSubmit={handleSubmit}>
      <Columns n={{2: '500px'}} gap={rhythm(1/2)}>
        <Input css={inputStyle} required value={fields.first} onChange={fieldSetter('first')} placeholder="First Name" border />
        <Input css={inputStyle} required value={fields.last} onChange={fieldSetter('last')} placeholder="Last Name" border />
        <Input css={inputStyle} required value={fields.email} onChange={fieldSetter('email')} type="email" placeholder="Email" border />
        <Input css={inputStyle} required value={fields.phone} onChange={fieldSetter('phone')} placeholder="Phone" border />
        <Input css={inputStyle} requried value={fields.address} onChange={fieldSetter('address')} placeholder="Address" border />
        <Input css={inputStyle} requried value={fields.city} onChange={fieldSetter('city')} placeholder="City" border />
        <Input css={inputStyle} requried value={fields.state} onChange={fieldSetter('state')} placeholder="State" border />
        <Input css={inputStyle} requried value={fields.zip} onChange={fieldSetter('zip')} placeholder="Zip" border />
      </Columns>


      <CardElement 
        css={{padding: '5px', border: '1px solid', borderRadius: '2px', marginBottom: rhythm(1/2)}} 
        options={{style: cardStyle, hidePostalCode: true}}
      />

      <div css={{position: 'relative'}}>
        <Textarea css={[inputStyle, textareaStyle]} placeholder="Comments (optional)" value={fields.comments} onChange={updateComments} border />
        <div css={commentsCounterStyles}>{fields.comments.length}/500</div>
      </div>

      <label css={inputStyle}>
        <input required type="checkbox" checked={fields.agree} onChange={fieldSetter('agree', 'checked')} />{' '}
        I agree to the <a target="_blank" href={agreementUrl}>membership agreement</a>
      </label>
      
      <Center>
        <Button disabled={status === 'processing'} secondary>{status === 'unpaid' ? 'Checkout' : 'Processing...'}</Button>
      </Center>

      {error && <Message type="error">{error}</Message>}
    </form>
  );
}

export default CheckoutForm;