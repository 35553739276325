import React from 'react';
import Button from './Button';
import {css} from '@emotion/core';
import Input from './Input';
import {rhythm} from 'src/styles/util'
import { MQ } from '../styles/constants';

const formStyle = css`
  margin: ${rhythm(1)} 0;
  display: flex;
  justify-content: center;
  > input {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: ${rhythm(12)};
    margin-right: ${rhythm(1/2)}
  }

  ${MQ.SM} {
    flex-wrap: wrap;

    > input {
      width: 100%;
      margin-right: 0;
      margin-bottom: ${rhythm(1/2)};
    }
  }
`

const Subscribe = (props) => (
  <form css={formStyle} action="https://foxcreekfarmcsa.us16.list-manage.com/subscribe/post?u=ef8ea1924a1b9846bfb064314&amp;id=ab0623ae2d" method="post" id="subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate>
    {/*  <h2>Mailing list</h2>  */}
    <Input type="email" placeholder="email address" name="EMAIL" />

    {/*  Real people should not fill this in */}
    <div css={css`position: absolute; left: -5000px;`} aria-hidden="true">
      <input type="text" name="b_3081a2b808ee4dde89e725907_6eb796eec8" tabIndex="-1" />
    </div>

    <Button secondary title="Subscribe to our newsletter">Subscribe</Button>
  </form>
);

export default Subscribe;