import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import { rhythm } from '../styles/util';

const bucketUrl = 'https://fcfcsa.s3.us-east-2.amazonaws.com'
const style = css`
  display: flex;
  margin: ${rhythm(1)} 0;
  > a { flex: 1 1 0; }
  img { width: 100%; height: auto; }

  @media (max-width: 900px) {
    > :nth-of-type(n+5) { display: none; }
  }

  @media (max-width: 500px) {
    > :nth-of-type(n+3) { display: none; }
  }
`;

const InstaFeed = () => {
  // default: link to instagram profile
  const [posts, setPosts] = useState(Array(6).fill('https://instagram.com/foxcreekfarmcsa/'))
  useEffect(() => {
    fetch(`${bucketUrl}/instagram-post-ids.json`).then(async res => {
      setPosts((await res.json()).map(id => `https://instagram.com/p/${id}`))
    })
  })

  return (
    <div css={style}>
      {posts.map((postURL, i) => 
        <a key={i} target="_blank" href={postURL}>
          <img src={`${bucketUrl}/instagram-${i}.jpg`} alt={`Instagram image #${i+1}`} />
        </a>)}
    </div>
  );
};

export default InstaFeed;